function getHeaders(){
    var req = new XMLHttpRequest();
    req.open('GET', document.location.href, false);
    req.send(null);
    var headerArr = req.getAllResponseHeaders().split('\n');
    var headers = {};
    headerArr.forEach(item=>{
        if(item!==''){
            var index = item.indexOf(':');
            var key = item.slice(0,index);
            var value = item.slice(index+1).trim();
            headers[key] = value
        }

    })
    return headers
}

const util={
    getHeaders:getHeaders
}

module.exports=util
