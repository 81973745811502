<template>
  <div id="launch-btn">
    <wx-open-launch-weapp
        id="launch-btn"
        :appid="wechatLiftAppId"
        username="gh_8d04fae0bd14"
        :path="wechatLiftPath"
        @error="handleErrorFn"
        @launch="handleLaunchFn"
    >
      <script type="text/wxtag-template">
        <button class="test-btn">打开</button>
        <style>
          .test-btn{
            width:100%;
            background: #f24f45;
            border-radius: 20px;
            padding:0 10px;
            color:#fff;
            font-size:16px;
            border:none;
          }
        </style>
      </script>
    </wx-open-launch-weapp>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk';
import request from "@/api";
import util from '@/utils/index'
import axios from 'axios'
export default {
  name: "index",
  components: {},
  data() {
    return {
      wechatLiftPath:'main/pages/nativeindex/nativeindex?fr=p199&pg=charge&ct=360700&sv=1&ch=36070001990102&bk=13021916',
      qrSign:'',
      wechatLiftAppId:'wxd2ade0f25a874ee2'
    }
  },
  computed: {},
  created() {
    /*alert(window.location.href.split('#')[0])*/
    /*let  head=util.getHeaders()
     console.log(head)
     Object.keys(head).forEach(function(key){
       alert(key,head[key]);
     });*/
    /*alert(head.date)
    alert(head.qrSign)*/
    /*let qrSign=head.qrSign*/
    /*let qrSign=this.$route.query.qrSign
    alert(qrSign)
    if (qrSign){
      this.qrSign=JSON.parse(qrSign)
      alert(this.qrSign)
      let da=this.qrSign.wechatLiftPath.replaceAll('-','&')
      this.wechatLiftPath=da
      alert(this.wechatLiftPath)
      this.wechatLiftAppId=this.qrSign.wechatLiftAppId
    }*/
    /*this.wxmini()*/
    /*this.$http.get(
            'https://api.weixin.qq.com/cgi-bin/token?grant_type=client_credential&appid=wxd596b98e0b20485c&secret=6ae04f87570c3f1674290d788d1f8fb9'
        )
        .then((res) => {
          let access_token = res.data.access_token;
          this.$http.post('https://api.weixin.qq.com/wxa/generatescheme?access_token=' + access_token, {
                jump_wxa: {},
                expire_type: 1,
                expire_time: 24 * 60 * 1000,
                expire_interval: 1,
              })
              .then((res) => {
                location.href = res.openlink
              });
        });*/
    let openLink=this.$route.query.openLink
    if (openLink!=null && openLink){
      location.href =openLink
      document.addEventListener("WeixinJSBridgeReady", function() {
            WeixinJSBridge.call("closeWindow");
          },
          false
      );
    }else {
      location.href ='weixin://dl/business/?t=UC2ShJ5ksmf'
      document.addEventListener("WeixinJSBridgeReady", function() {
            WeixinJSBridge.call("closeWindow");
          },
          false
      );
    }

  },
  async mounted() {

  },
  methods: {
    getObtainScan(){
      request.get('/qr/config?id=20230407642168028e22c6d0d0000001',{params:{}}).then(res=>{
        console.log(res)
        alert(res.data)
        if (res.data){
          wx.config({
            debug: true,
            appId: this.qrSign.appId,  // 公众号appId
            timestamp: this.qrSign.timestamp,
            nonceStr: this.qrSign.nonceStr,
            signature: this.qrSign.signature,
            jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage', 'checkJsApi', 'scanQRCode','chooseImage', 'previewImage'], // 必填，需要使用的JS接口列表
            openTagList: ['wx-open-launch-weapp'] // 可选，需要使用的开放标签列表
          });
          wx.ready(function () {
            // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中
          });

          wx.error(function (res) {
            console.log(res)
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
          });
        }
      }).catch(err=>{
        alert(err.message)
      })
    },
    // 方法
    wxmini() {
      /*wx.config({
        debug: true,
        appId: this.qrSign.appId,  // 公众号appId
        timestamp: this.qrSign.timestamp,
        nonceStr: this.qrSign.nonceStr,
        signature: this.qrSign.signature,
        jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage', 'checkJsApi', 'scanQRCode','chooseImage', 'previewImage'], // 必填，需要使用的JS接口列表
        openTagList: ['wx-open-launch-weapp'] // 可选，需要使用的开放标签列表
      });*/

      wx.config({
        debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
        appId: 'wx935aa8876c5eeb5a', // 必填，公众号的唯一标识
        timestamp: 1681699827, // 必填，生成签名的时间戳
        nonceStr: 'f806e294-c47d-45dc-8f20-ec6fbe99196f', // 必填，填任意非空字符串即可
        signature: '2be2b12875050f9848fc2a2f176999f51d67483a', // 必填，填任意非空字符串即可
        jsApiList: ['showMenuItems'], // 必填，随意一个接口即可
        openTagList: ['wx-open-launch-weapp'], // 填入打开小程序的开放标签名
      })
      wx.ready(function () {
        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中
      });

      wx.error(function (res) {
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
      });
    },
    csweixin(){
      let _this = this;
      let myUrl = window.location.href.split('#')[0];
      this.axios.post("/index/jsapi", { url: myUrl }, res => {
        if (res.code == 10000) {
          _this.myWxConfig = res.data;
          // 先通过jsapi 获取 微信签名 等信息
          wx.config({
            debug: false,
            appId: _this.myWxConfig.appId,
            timestamp: _this.myWxConfig.timestamp,
            nonceStr: _this.myWxConfig.nonceStr,
            signature: _this.myWxConfig.signature,
            jsApiList: ["scanQRCode"],
            openTagList: ["wx-open-launch-weapp"] // 可选，需要使用的开放标签列表，例如[‘wx-open-launch-weapp’]
          });

          wx.ready(function() {
            console.log("ready success");
          });
          wx.error(function() {
            console.log("error fail");
          });
        } else {
          _this.weui.alert(res.message);
        }
      });
    },
    handleErrorFn(e) {
      console.log("fail", e.detail)
    },
    handleLaunchFn(e) {
      console.log("success")
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
